import {Print} from '../Libraries/Print';
const jsPrintButtons = document.querySelectorAll(".js-print");
for(const pBtn of jsPrintButtons) {
    const printEl = pBtn.dataset.print;
    const printCss = pBtn.dataset.css;
    const pageSize = pBtn.dataset.pageSize;

    pBtn.addEventListener("click", () => {
        new Print(printEl, {
            css: printCss,
            setPageSize: pageSize
        });
    });
}