;(() => {    
    const jsCaptchaForms = document.querySelectorAll('.js-captcha');
    
    for(let cForm of jsCaptchaForms) {
        cForm.appendChild(Object.assign(
            document.createElement("input"), {
            "type": "hidden",
            "name": "captcha",
            "value": "true"
        }));
    }

})();