
export class ExportCsv {

    constructor(selector, options = {}) {
        this.table = document.querySelector(`${selector}`);
        this.options = options;

        if (!this.table) {
            return "Cold not find element with this selector";
        }

        this.get();
    }


    download(data) {

        // Creating a Blob for having a csv file format
        // and passing the data with type
        const blob = new Blob([data], { type: 'text/csv' });

        // Creating an object for downloading url
        const url = window.URL.createObjectURL(blob)

        // Creating an anchor(a) tag of HTML
        const a = document.createElement('a')

        // Passing the blob downloading url
        a.setAttribute('href', url)

        // Setting the anchor tag attribute for downloading
        // and passing the download file name
        a.setAttribute('download', 'download.csv');

        // Performing a download with click
        a.click()
    }

    objectToCsv(data) {

        const csvRows = [];
        let headers;

        /* Get headers as every csv data format 
        has header (head means column name)
        so objects key is nothing but column name 
        for csv data using Object.key() function.
        We fetch key of object as column name for 
        csv */
        
        /* Using push() method we push fetched 
        data into csvRows[] array */
        if(this.options?.customObjectHeadingsNumber) {
            headers = Object.keys(data[this.options?.customObjectHeadingsNumber]);
        }else {
            headers =  Object.keys('titles');
        }


        if(this.options?.hideCol) {
            delete headers[this.options?.hideCol]; 
        }

        csvRows.push(headers.join(','));

        for (const row of data) {
        // Loop to get value of each objects key
            let headersIndex = 0      

            const values = headers.map(header => {    
                //console.log(row[header]);
                const val = row[header] ?? '';
                return `"${val}"`;
            });

            // To add, sepearater between each value
            csvRows.push(values.join(','));
        }

        /* To add new line for each objects values
           and this return statement array csvRows
           to this function.*/
        //    console.log(csvRows);    
        return csvRows.join('\n');
    }


    convertTableToObject() {
        const result = [];
        const tableTrs = this.table.querySelectorAll(".row");
        const tableThs = this.table.querySelectorAll(".col");

        let thIndex = 0;
        tableTrs.forEach((tr, index) => {

            if(this.options?.removeRowElement) {
                const removeEl = tr.querySelector(`${this.options?.removeRowElement}`);
                if(removeEl) {
                    removeEl.remove();
                }
            }

            const tdEls = tr.querySelectorAll(".col");
        
            let tdIndex = 0;
            let feed = {};
            for(const el of tdEls) {            
                const thName = tableThs[tdIndex].innerText;
                feed[thName] = el.innerText;

                tdIndex++;
            }
            
            result.push(feed);

        });

        return result;
    }


    async get() {
        // JavaScript object      

        let data = this.convertTableToObject();
        const csvdata = this.objectToCsv(data);
        //console.log(csvdata);
        const popupWin = window.open('', '_blank', 'width=1400,height=800');
        console.log(popupWin);
        popupWin.document.open();
        popupWin.document.write(`
            <html> 
            <head>
       
            </head>
            <body onload="window.print()">
                ${csvdata}               
            </body>
            </html>`);
        this.download(data);
    }

}

// Getting element by id and adding
// eventlistener to listen everytime
// button get pressed
