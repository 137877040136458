function getTrans(key) {
	let url = `${location.origin}/translations/${key}`;


	fetch(url)
		.then((res) => res.json())
		.then((data) => {
			console.log(data);
			return data;
			// if(data.status == 'returned status') {}

		})
		.catch((err) => {
			// console.log(err);
		});
}

(() => {
const indexTranslation = document.querySelector(".translation-index");

if (!indexTranslation) {
	return;
}

const selectLang = document.querySelector(".js-translationExport");
selectLang.addEventListener(
	"change",
	() => {
		let langId = selectLang.selectedOptions;
		const langJson = langId[0].value;
		let url = '/admin/translations/'+langJson+'/json';
		location.href = url;
	}
)

})();