import { Utility } from "./Utility";

; (() => {

	const jsFormSubmitters = document.querySelectorAll(".js-submit");

	for (let submitter of jsFormSubmitters) {
		submitter.addEventListener("click", submitCurrentForm);
	}

})();

; (() => {


	const jsFormSubmitters = document.querySelectorAll(".js-change-submit");

	for (let submitter of jsFormSubmitters) {
		submitter.addEventListener("change", (e) => new Utility().submitCurrentForm(e));
	}

})();
